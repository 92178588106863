import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import {Breadcrumb,Container,Row,Col} from "react-bootstrap";
import "./Breadcrumbs.scss"
// markup
const Breadcrumbs = (props ) => {

  return ( 
        <React.Fragment>
           <div className="breadcrumb-wrap">
           <Container>
             <Row>
               <Col md='12'>
                  <Breadcrumb>
                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active>{props.Pagename}</Breadcrumb.Item>
                  </Breadcrumb>
               </Col>
             </Row>
           </Container>
           </div>
        </React.Fragment>
    )
}
export default Breadcrumbs